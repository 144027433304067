.edit_mode {
	// Hide the placeholder image for the missing favicon image
    .selectedNodes .nodePicker-site-name img {
        display: none;
    }
}

.edit_mode .em-col-2 {
    .column {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: flex-start;

        .columnBounds {
            display: flex;
            flex-flow: row wrap;
            width: 100%;

            &>a.addPageElementTop {
                flex-basis: 100%;
            }

            &>div.drag {
                flex-basis: calc(100% / 2);
                &:first-of-type {
                    flex-basis: 100%;
                }
            }
        }
    }
}

.edit_mode .em-col-3 {
    .column {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: flex-start;

        .columnBounds {
            display: flex;
            flex-flow: row wrap;
            width: 100%;

            &>a.addPageElementTop {
                flex-basis: 100%;
            }

            &>div.drag {
                flex-basis: calc(100% / 3);
                &:first-of-type {
                    flex-basis: 100%;
                }
            }
        }
    }
}

.edit_mode .em-col-4 {
    .column {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: flex-start;

        .columnBounds {
            display: flex;
            flex-flow: row wrap;
            width: 100%;

            &>a.addPageElementTop {
                flex-basis: 100%;
            }

            &>div.drag {
                flex-basis: calc(100% / 4);
                &:first-of-type {
                    flex-basis: 100%;
                }
            }
        }
    }
}

.edit_mode .em-col-5 {
    .column {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: flex-start;

        .columnBounds {
            display: flex;
            flex-flow: row wrap;
            width: 100%;

            &>a.addPageElementTop {
                flex-basis: 100%;
            }

            &>div.drag {
                flex-basis: calc(100% / 5);
            }
        }
    }
}
