@media only screen and (min-width: 48em) {
	.user_mode {
		.mission-container {
			position: relative;

		}
	}
}

@media only screen and (min-width: 64em) {
	.user_mode {
		.mission-container {
			&:before {
				content: ' ';
				background: transparent url(../images/custom/scroll-icon-1.png) center center no-repeat;
				display: block;
				height: 76px;
				width: 76px;
				position: absolute;
				top: -104px;
				left: calc(50% - 38px);
				z-index: 100;
			}
		}
	}
}
