// Edit Mode Footer
.edit_mode {

	.snFooterContainer {
		position: relative;

		&:before {
			content: '';
			position: absolute;
			display: block;
			background: rgba(136,9,0,.85);
			height: 100%;
			width: 100%;
			top: 0;
			left: 0;
			z-index: 1;
			opacity: 0;
			transition: opacity $transition-standard;
		}

		&:after {
			content: 'Please Edit your Footer Information on your Assets page. Thank You!';
			position: absolute;
			display: block;
			width: 100%;
			text-align: center;
			color: white;
			text-transform: uppercase;
			left: 0;
			top: calc(50% - 25px);
			font-size: 24px;
			z-index: 2;
			opacity: 0;
			transition: opacity $transition-standard;
		}

		&:hover {

			&:before,
			&:after {
				opacity: 1;
			}

		}

	}

}

// Hide head code access
body{
  &.edit_mode{
    a[href*='edit_header_code']{
      display: none;
    }
  }
  &#edit-head-code {
    a[href*='edit_header_code']{
      display: block;
    }
  }
}