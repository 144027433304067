@mixin meta-container($userColumns, $editColumns, $padding) {
   .column {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: flex-start;

      .pageEl {
         flex-basis: calc(100% / #{$userColumns});
         padding: $padding;
      }
   }

   .edit_mode & {
      .column .columnBounds {
         display: flex;
         flex-flow: row wrap;
         width: 100%;

         & > a.addPageElementTop {
            flex-basis: 100%;
         }

         & > div.drag {
            flex-basis: calc(100% / #{$editColumns});
         }
      }
   }
}

.layoutContainer.se-col-4 {
   @include meta-container(1,1, 5px)
}

.layoutContainer.se-col-5 {
    @include meta-container(1,1, 5px)
 }

.layoutContainer.se-col-6 {
   @include meta-container(2,2,5px)
}

.layoutContainer.se-col-8 {
   @include meta-container(2,2,5px)
}

.layoutContainer.se-col-10 {
   @include meta-container(4,2,5px)
}
// Tablet
@media only screen and (min-width: 768px) {
   .layoutContainer.se-col-4 {
      @include meta-container(2,2,5px)
   }
   .layoutContainer.se-col-5 {
    @include meta-container(5,5,5px)
 }

   .layoutContainer.se-col-6 {
      @include meta-container(3,3,5px)
   }

   .layoutContainer.se-col-8 {
      @include meta-container(4,4,5px)
   }

   .layoutContainer.se-col-10 {
      @include meta-container(5,5,5px)
   }
}
// Desktop
@media only screen and (min-width: 1024px) {
   .layoutContainer.se-col-4 {
      @include meta-container(4,4,5px)
   }

   .layoutContainer.se-col-5 {
    @include meta-container(5,5,5px)
 }

   .layoutContainer.se-col-6 {
      @include meta-container(6,6,5px)
   }

   .layoutContainer.se-col-8 {
      @include meta-container(8,4,5px)
   }

   .layoutContainer.se-col-10 {
      @include meta-container(10,5,5px)
   }
}
