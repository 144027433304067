@mixin container_with_border() {
	background: #fff;
	padding: 0 25px 20px 25px;
	border: 2px solid #efefef;
}

//Not News Page Styling
.site-background {
	//background-size: 100% auto;
}

#siteContainer {
	padding-top: 45px;
}

body.user_mode {
	&#home:not(.newsPage) {
		#topNav {
			margin-bottom: 0 !important;
		}

		.theme-sub-nav {
			display: none;
		}

		#displayBodyHeader {
			display: none;
		}

		.site-background {
			//top: 100px;
		}

		#siteContainer {
			#siteContainer.container-fluid,
			& {
				padding: 0;
				max-width: 100%;
			}

			#panelTwo {
				padding: 0;
				background: transparent;
			}
		}

		.layoutContainer {
			padding: 0 calc((100vw - 1200px) / 2);

			&.home-news-slider,
			&.training-container,
			&.get-involved-container {
				padding: 0;
			}

			&.events-container,
			&.stay-connected-container {
				padding-top: 0;
				padding-bottom: 0;
				.column-1 {
					@include container-with-border;
				}
			}

			&.cta-section {
				.pageElement {
					margin-top: 0;
					margin-bottom: 0;
				}

				.pageEl .pageElement {
					margin-top: 0;
					margin-bottom: 0;
				}
			}

			&.row.layout-100.sponsorScroller {
				padding: 0;
			}
		}

		.layoutContainer {
			//background-color: #d9d9d9;
		}

		@media only screen and (min-width:48em) {
			.layoutContainer.row.layout-66-33 {
				.column-1,
				.column-2 {
					@include container-with-border;
				}

				// .column-1 {
				// 	width: 68%;
				// }

				// .column-2 {
				// 	width: 29%;
				// 	margin-left: 3%;
				// }
			}
		}
	}

	&#show-subnav {
		display: block !important;
	}
}

//Not Team Page Styling
body:not(.teamPage) {
	.theme-sub-nav {
		// display: none !important;
	}
}

body.newsPage {
	.newsItemElement {
		.previewText {
			display: none;
		}
	}
}

//Not Home Page Styling
body:not(.home) {
	#panelTwo {
		max-width: $maxWidth;
		margin: auto;
		display: block;
		width: 100%;
		float: none;
		padding-bottom: 30px;
	}
}

//Custom Overides & Classes
.eventAggregatorElement {
	.sn-share-icons {
		float: left;
		display: block;
		width: 100%;
	}
}

.layoutContainer {
	.pageEl {
		&.full-width {
			width: 100% !important;
			display: block;
			margin: auto;
		}

		.sn-call-to-action {
			padding: 0;

			&:after,
			&:before {
				display: none;
			}
		}

		&.cta-blue,
		&.cta-grey,
		&.cta-red {
			.sn-call-to-action {
				img {
					-webkit-filter: grayscale(100%);
					filter: grayscale(100%);
				}

				&.sn-variation-slide-up:hover .sn-call-to-action-overlay,
				.sn-call-to-action-overlay {
					height: 100%;
					min-height: 100%;
				}

				&:hover .sn-call-to-action-overlay {
					.sn-call-to-action.sn-variation-slide-up .sn-call-to-action-overlay {
						.sn-call-to-action-overlay-text {
							margin-right: 15px;
						}
					}
				}

				.sn-call-to-action-overlay-text {
					margin-right: 10px;
					transition: margin-right 300ms ease-in-out;

					.sn-call-to-action-title {
						&:first-line {
							font-weight: normal;
							color: #fff;
						}
						display: table-caption;
						word-wrap: normal;
						font-size: 36px;
						margin-bottom: 10px;
					}
					font: bold 1.5em/1.5em $fontOne;
					height: auto;
					position: absolute;
					right: 0;
					bottom: 0;
					text-align: right;
				}

				.sn-call-to-action-overlay {
					&:before {
						content: '';
						background-image: url('../images/clay/slash.svg');
						background-position: left bottom;
						background-repeat: no-repeat;
						position: absolute;
						left: 0;
						right: 0;
						top: 0;
						bottom: 0;
						width: 100%;
						height: 100%;
						display: block;
						opacity: .13;
					}
				}
			}
		}

		&.cta-red {
			.sn-call-to-action {
				&:hover {
					.sn-call-to-action-overlay {
						background: rgba(darken($link-color, 6%), 0.8);
					}
				}

				.sn-call-to-action-overlay {
					transition: background 300ms ease-in-out;
					background: rgba($link-color, 0.7);
				}
			}
		}

		&.cta-grey {
			.sn-call-to-action {
				&:hover {
					.sn-call-to-action-overlay {
						background: rgba(#000, 0.8);
					}
				}

				.sn-call-to-action-overlay {
					transition: background 300ms ease-in-out;
					background: rgba(#000, 0.7);
				}
			}
		}

		&.cta-blue {
			.sn-call-to-action {
				transition: background 300ms ease-in-out;

				&:hover {
					.sn-call-to-action-overlay {
						background: rgba(darken($accent-color, 5%), 0.8);
					}
				}

				.sn-call-to-action-overlay {
					transition: background 300ms ease-in-out;
					background: rgba($accent-color, 0.7);
				}
			}
		}

		.adElement {
			padding: 30px 15px;
			margin: 0;

			.adbox {
				padding-bottom: 0 !important;
			}
		}
	} // &.ad-section {
	//     background: $ad-section;
	//     margin: 0;
	// }
	// &.cta-section {
	//     background-color: $accent-color;
	// }
	.column-2 {
		.pageElement.adElement {
			padding: 10px 0;
			margin: 0;
		}

		.pageEl:first-child .pageElement.adElement {
			padding: 0 0 10px;
			margin: 0;
		}
	}
}

.has-sub-nav {
	#topNavPlaceholder {
		//height: 100px;
	}
}

.sn-background-layout {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	padding-top: 30px;
	padding-bottom: 30px;
}

.news-section.sn-background-layout {
	background-size: auto;
	background-repeat: repeat;
}

button.themeStyle {
	display: flex;
	align-items: stretch;
	justify-content: center;
	width: 199px;
	background: $highlight-color;
	color: #fff;
	height: 38px;
	border: none;
	margin: 0 auto;
}

button.themeStyle a {
	line-height: 38px;
	color: #fff;
	font-size: 12px;
	height: 38px;
	width: 100%;
}

.sn-transparent {
	background: transparent !important;
}

//makes the news slideshow positioned relative
.user_mode .newsSlideShow {
	position: relative;

	.newsSlideShow-container {
		overflow: visible;
		margin-bottom: 15px;
	}

	.newsSlideShow-article {
		position: absolute; // overflow: visible;
		transition: all .2s linear;

		img {
			top: auto !important;
			float: left;
			position: relative;
			left: auto !important;
			width: 100% !important;
		}

		.newsSlideShow-headline {
			top: auto !important;
			float: left;
			position: relative;
			width: 100% !important;
			left: auto !important;
		}

		&.current {
			position: relative !important;
			transition: all .2s linear;
		}
	}
}

.sn-slideshow {
	.newsSlideShow {
		.newsSlideShow-container {}

		.newsSlideShow-thumbnails {
			display: none;
		}
	}
}

#footerLogo {
	display: inline-block;
	line-height: 1.5em;
	width: 65px;
	height: 45px;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	margin-right: 30px;
	padding: 0;
	@media only screen and (max-width: 768px) {
		width: 100%;
		height: 150px;
	}
}

.newsAggregatorElement .condensed>a img {
	max-width: 120px;
	width: 100%;
	@media only screen and (max-width: 768px) {
		max-width: 100%;
	}
}

.removePadding {
	.pageElement {
		padding: 0 !important;
	}
}

.banner-img-300 {
	img {
		max-width: 300px;
	}
}

.sn-newsagg {
	.newsAggregatorElement {
		background: transparent;

		.item {
			width: 32.33%;
			float: left;
			border: none;
			background: #fff;
			padding: 0;
			margin-left: 1%;
			margin-bottom: 1%;
			@media only screen and (max-width: 768px) {
				width: 100%;
				margin-left: 0;
				margin-bottom: 1%;
			}

			>a {
				float: left;
				width: 100%;
				margin: auto;
				padding: 0;

				img {
					width: 100%;
				}
			}

			ul {
				padding: 0;
				float: left;
				width: 100%;

				li {
					vertical-align: middle;
					padding: 0;
					min-height: 75px;

					h4 {
						border-top: 1px solid $link-color;

						a {
							color: $accent-color;
							display: block;
							padding: 10px 7px;
							transition: color 300ms ease-in-out;
							font: bold 18px/1.5em $fontOne;

							&:hover {
								text-decoration: none;
								color: $link-color;
							}
						}
					}
				}
			}

			.commentsAndMore,
			.dateAuthor,
			.teaser {
				display: none;
			}
		}

		.extendedOptions {
			display: none;
		}
	}
}

,
// #home #adWrapperBottomLeaderboard,
// #home #adWrapperTopLeaderboard {
//     background: $ad-section;
//     margin: 0;
// }
// edit_mode
.layoutContainerAdmin {
	margin: auto;
}

#networkNavBar {
	display: none;
}

.news-section .column-1.column.col-sm-12.col-md-8 .pageEl {
	background: #fff;
	padding-left: 2%;
}

// Responsive Tweaks
@media only screen and (max-width: 480px) {
	//  #topSearchBar {
	//      display: none;
	//  }
}

@media only screen and (max-width: 768px) {
	//  #topSearchBar {
	//      display: none;
	//  }
	.sn-background-layout {
		padding-top: 15px;
		padding-bottom: 15px;
	}

	// body#home:not(.newsPage) .layoutContainer.row.layout-66-33 .column-1 {
	// 	width: 65%;
	// 	margin-left: 2%;
	// }

	// body#home:not(.newsPage) .layoutContainer.row.layout-66-33 .column-2 {
	// 	width: 29%;
	// 	margin-left: 0;
	// }
}

@media only screen and (min-width: 768px) {
	.news-section .column-1.column.col-sm-12.col-md-8 .pageEl {
		background: none;
		padding-left: 0;
	}

	.sn-slideshow {
		position: relative;

		.newsSlideShow {
			.newsSlideShow-container {
				margin-bottom: 7.5px;
				display: flex;
				align-items: flex-end;
			}

			.newsSlideShow-thumbnails {
				display: flex;
				flex-wrap: wrap;
				position: relative;
				z-index: 10;
				left: auto;
				bottom: auto;
				width: 100%;
				height: auto;
				box-shadow: none;
				text-align: left;
				transform: none;
				align-items: flex-end;
				justify-content: inherit; //flex-wrap: inherit;
				background: transparent;
				padding-bottom: 15px;

				li {
					width: 33.33%;
					vertical-align: top; //
					@media only screen and (max-width: 1024px) {
						width: 50%;
					}
					@media only screen and (max-width: 768px) {
						width: 33.33% !important;

						a {
							opacity: .6;
							transition: opacity 300ms ease-in-out;

							&.current {
								opacity: 1;
							}

							h3 {
								//display: none !important;
							}
						}
					}

					a {
						padding: 7.5px;
						opacity: .6;
						transition: opacity .2s linear;

						&:before {
							display: none;
						}

						&:hover {
							text-decoration: none;
						}

						img {
							display: block;
						}

						h3 {
							color: #fff !important;
							text-decoration: none;
							display: block;
							padding: 7px;
							transition: color 300ms ease-in-out;
							font: bold 12px/1.2em $fontOne;
							border-top: 1px solid $link-color;
							background: $accent-color;
							min-height: 45px;
							font-weight: 300;
						}
						&.current {
							opacity: 1;
							transition: opacity .2s linear;
						}
					}
				}
			}
		}
	}
}

@media only screen and (min-width: 1024px) {
	.sn-slideshow .newsSlideShow .newsSlideShow-thumbnails li a h3 {
		font: 300 16px/1.2em $fontOne;
		min-height: 60px;
	}

	body#eventPage .theme-sub-nav {
		display: block !important;
	}

	.topNav-inner-wrapper {
		max-width: 1200px;
		margin-left: auto;
		margin-right: auto;
	}
}

@media only screen and (min-width: 1200px) {
	.page-manager-visible.nav-fixed {
		body#eventPage {
			.topNav-inner-wrapper {
				margin-left: calc((100vw - 1140px) / 2);
				margin-right: calc((100vw - 1260px) / 2);
			}
		}
	}
}
