.user_mode {
    .layoutContainer.home-news-slider {
        overflow: hidden;
        padding: 0;
        background-color: #fff;

        .column {
            padding: 0;

            .pageElement {
                padding: 0;
                margin: 0;

                h2,
                p {
                    display: none;
                }
            }

            .sn-media-slider {
                width: 100%;
                max-width: 100%;
                font-family: $fontOne; //max-height: 240px;
                .flexslider {


                    //height: 240px !important;
								}
								

                // .slider-pagination {
                //     bottom: 26px;

                //     .paging-item {
                //         background-color: #fff;
                //         height: 13px;
                //         width: 13px;
                //         margin: 0 1px 0 2px;

                //         &.flex-active {
                //             background-color: $highlight-color;
                //         }
                //     }
                // }

                .media-wrapper a img {
                    left: 0 !important;
                    width: 100% !important;
                    height: auto !important;
                    min-height: 240px;
                }

                .slider {
									margin-bottom:0;
                    .slide-overlay {
                        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(15, 51, 116, 0.7) 100%);
                        height: 100%;
                        overflow: hidden;
                        box-sizing: border-box;
                    }

                    .slide-text.bottom-center {
                        vertical-align: top;
                        padding-top: 45px;
                    }

                    .slide-title {
                        font-size: 26px;
                        text-shadow: none;
                        margin: 25px 0 0; //text-transform: uppercase;
												max-width: 285px;
												transition: font-size .2s ease;
                    }

                    .slide-description {
                        font-size: 12px;
                        line-height: 1.2;
                        text-shadow: none;
                        margin: 0; //box-shadow: 3px 3px 5px rgba(0,0,0,.1);
                        font-weight: 400;
                        text-transform: uppercase;
                    }
                }

                .flex-direction-nav {
                    a {
                        //top: 267px;
                        width: 55px;
                        height: 83px;
                        line-height: 83px;
                        background-color: $highlight-color;
                        color: transparent;

                        &:before {
                            width: 55px;
                            text-align: center;
                            text-shadow: none;
                        }

                        &.flex-prev {
                            border-radius: 0 10px 10px 0;
                        }

                        &.flex-next {
                            border-radius: 10px 0 0 10px;
                        }
                    }
                }

                &:hover .flex-direction-nav {
                    .flex-prev {
                        opacity: 1;
                        left: 0;
                    }

                    .flex-next {
                        opacity: 1;
                        right: 0;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 48em) {
    // Tablet (768px)
    .user_mode {
        .layoutContainer.home-news-slider {
            .column {
                .sn-media-slider {
                    .slider {
                        .slide-title {
                            font-size: 32px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 64em) {
    // Tablet (1024px)
    .user_mode {
        .layoutContainer.home-news-slider {

            .column {
                border: 0 !important;
                .sn-media-slider {
                    //max-height: 490px;
                    .flexslider {
                        //height: 490px !important;
                        margin: 0;
                    }

                    .slider-pagination {
                        bottom: 26px;
                    }

                    .media-wrapper a img {
                        //min-height: 490px;
                    }

                    .slider {
                        .slide-text {
                            padding-top: 125px;
                            display: flex;
                            max-width: 550px;
                            align-items: flex-end;
                        }

                        .slide-title {
                            font-size: 50px;
                            letter-spacing: 1px;
                            line-height: 1;
                            max-width: 100%;
                        }

                        .slide-description {
                            font-size: 18px;
                            line-height: 1.5;
                            letter-spacing: 1px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 80em) {
    // Desktop (1280px)
    .user_mode {
        .layoutContainer.home-news-slider {

            .column {
                border: 0 !important;
                .sn-media-slider {
                    //max-height: 490px;
                    .flexslider {
                        //height: 490px !important;
                        margin: 0;
                    }

                    .slider-pagination {
                        bottom: 26px;
                    }

                    .media-wrapper a img {
                        //min-height: 490px;
                    }

                    .slider {
                        .slide-text {
                            padding-top: 125px;
                            display: flex;
                            max-width: 550px;
                            align-items: flex-end;
                        }

                        .slide-title {
                            font-size: 60px;
                            letter-spacing: 1px;
                            line-height: 1;
                        }

                        .slide-description {
                            font-size: 18px;
                            line-height: 1.5;
                            letter-spacing: 1px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 90em) {
    // Desktop (1440px)
    .user_mode {
        .layoutContainer.home-news-slider {
            .column {
                .sn-media-slider {
                    //max-height: 490px;
                    .flexslider {
                        //height: 490px !important;
                    }

                    .slider-pagination {
                        //bottom: 26px;
                    }

                    .media-wrapper a img {
                        //min-height: 490px;
                    }

                    .slider {

                        .slide-text {
                            padding-top: 175px;
                            display: flex;
                            max-width: 600px;
                            align-items: flex-end;
                            &.bottom-center {
                                //padding-top: 175px;
                            }
                        }

                        .slide-title {
                            font-size: 60px;
                            line-height: 1;
                        }

                        .slide-description {
                            //font-size: 18px;
                            line-height: 1.5;
                            letter-spacing: 1px;
                        }
                    }
                }
            }
        }
        .home-slider .sn-media-slider .slider {
            .slide-title {
                font-size: 72px;
                font-weight: 900;
            }
        }
    }
}

@media only screen and (min-width: 120em) {
    // Desktop (1920px)
    .user_mode {
        .layoutContainer.home-news-slider {
            .column {
                .sn-media-slider {
                    //max-height: 490px;
                    .flexslider {
                        //height: 490px !important;
                    }

                    .slider-pagination {
                        //bottom: 26px;
                    }

                    .media-wrapper a img {
                        //min-height: 490px;
                    }

                    .slider {

                        .slide-text {
                            padding-top: 175px;
                            display: flex;
                            max-width: 680px;
                            align-items: flex-end;
                            &.bottom-center {
                                //padding-top: 175px;
                            }
                        }

                        .slide-title {
                            font-size: 75px;
                            line-height: 1;
                        }

                        .slide-description {
                            //font-size: 18px;
                            line-height: 1.5;
                            letter-spacing: 1px;
                        }
                    }
                }
            }
        }
    }
}

.logged_in {
    .layoutContainer.home-news-slider {
        .column {
            .sn-media-slider {
                &:hover .flex-direction-nav {
                    .flex-prev {
                        left: 25px;
                    }

                    .flex-next {
                        right: 25px;
                    }
                }
            }
        }
    }
}
