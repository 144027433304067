// 5. Site Footer
.snFooterContainer {
	width: 100%; //max-width: $maxWidth;
	margin: 0 auto;
	background-color: #eee;
	border-top:6px solid $highlight-color;
}

#siteFooter {
	@extend %clearfix;
	display: block;
	margin: auto;
	padding: 10px 20px;
	position: relative;
	vertical-align: bottom;
	width: 100%;
	font-size: .8em;
	@media only screen and (min-width:64em){
		font-size: .6em;
	}
	@media only screen and (min-width:75em){
		font-size: .8em;
	}
	clear: both;
	text-align: center;

	>ul {
		padding: 0;
		@include displayCenter($maxWidth);
		@media only screen and (min-width:64em){
			@include displayCenter(900px);
		}
		@media only screen and (min-width:75em){
			@include displayCenter($maxWidth);
		}

		li {
			display: inline-block;
			padding: 0 .2em;
			min-height: 20px;

			a {
				display: inline-block;
			}

			&:hover {
				display: inline-block;
			}
		}
	}
}

#siteFooter li#poweredByNGIN {
	a {
		display: block;
		width: 100%;
		height: 20px;
		position: relative; //margin-left:50px;
		&:after {
			content: url($asset-path-for+'/logo_images/logo.svg');
			display: block;
			position: absolute;
			top: 8px;
			left: calc(50% - 60px);
			width: 120px;
		}

		img {
			display: none;
		}
	}
}

@media only screen and (max-width: 1024px) {
	#siteFooter {
		li {
			min-height: 0;
		}
	}

	#siteFooter li#poweredByNGIN {
		padding: 10px 0;
		width: 100%;
	}
}

@media only screen and (max-width: 640px) {
	#siteFooter {
		li {
			min-height: 0;
		}
	}

	#siteFooter li#poweredByNGIN {
		padding: 10px 0;
	}
}

@media only screen and (min-width:64em) {

	#siteFooter li#poweredByNGIN {
		a {
			margin-left: 50px;

			&:after {
				content: url($asset-path-for+'/logo_images/logo.svg');
				display: block;
				position: absolute;
				top: 8px;
        left: 0;
				width: 120px;
			}
		}
	}
}
