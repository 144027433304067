a.button-red {
	background: transparent !important;
	color: $highlight-color;
	padding: 10px 25px;
	border: 1px solid $highlight-color !important;
	text-decoration: none;
}

a.button-red:hover {
	background: $highlight-color !important;
	color: #fff !important;
	padding: 10px 25px;
	border: 0 !important;
}
