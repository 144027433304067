.user_mode {

    .training-container {
        margin-bottom: 0 !important;
        background: #000 url(../images/custom/facilities-bg-1.jpg) center top no-repeat;
        background-size: cover;

        a:hover {
            text-decoration: none;
        }

        .column-1 {

            .pageEl {
                box-sizing: border-box;
                margin: 0;
                padding: 0;
                &:first-of-type {
                    .textBlockElement {
                        margin-bottom: 0;
                        h3 {
                            margin-bottom: 0;
                            span {
                                color: #fff;
                                padding-bottom: 0;
                            }
                        }
                    }
                }
            }
            .cta-text-block {

                .textBlockElement {
                    position: relative;

                    h3,
                    a,
                    p,
                    span {
                        color: #fff;
                    }
                    .text {
                        color: #fff;
                        height: 50%;
                        overflow: hidden;
                        top: 25%;
                        left: 0;
                        position: absolute;
                        width: 100%;
                        padding: 0 50px;

                        h3 {
                            position: relative;
                            font-size: 36px;
                            line-height: 1.2;
                            height: 120px;
                            display: flex;
                            align-items: center;
                            a {
                                word-wrap: normal;
                            }
                            &:after {
                                content: '';
                                display: block;
                                width: 100px;
                                border-bottom: 5px solid $highlight-color;
                                position: absolute;
                                bottom: -3px;
                                left: calc(50% - 50px);
                            }
                        }
                    }

                    .leftTextImage {
                        width: 100% !important;
                        margin: 0;

                        img {
                            width: 100% !important;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width:48em) {
    .user_mode {
        .training-container {
            padding: 0 15px 15px 15px;
            background-size: auto;

            .column-1 {
                display: flex;
                flex-flow: row wrap;

                .pageEl {
                    max-width: 25%;
                    &:first-of-type {
                        width: 100%;
                        max-width: 100%;
                    }
                }
                .cta-text-block {
                    .textBlockElement {
                        .text {

                            height: 280px;

                            top: 0;
                            left: 0;
                            position: absolute;
                            width: 100%;
                            padding: 0 15px;

                            h3 {
                                font-size: 24px;
                                height: 120px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 64em) {
    .user_mode {
        .training-container {
            padding-bottom: 20px !important;
            .column-1 {
                padding: 0 calc((100vw - 1200px) / 2);

                .cta-text-block {

                    .textBlockElement {
                        margin: 0;
                        position: relative;
                        height: 100%;

                        &:before {
                            content: '';
                            box-shadow: inset 0px 0px 0px 40px rgba(255, 255, 255, 0);
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            position: absolute;
                            display: block;
                            transition: box-shadow .7s ease-in-out;
                        }
                        &:hover:before {
                            box-shadow: inset 0px 0px 0px 5px rgba(205, 47, 41, 1);
                        }
                        .leftTextImage {

                            img {
                                width: 100% !important;
                            }
                        }

                        .text {
                            height: 130px;
                            top: 175px;
                            transition: height 1s ease, top 1s ease;
                            h3 {
                                font-size: 36px;
                                margin-bottom: 15px;
                                &:after {
                                    display: none;
                                }
														}
														
														p:first-of-type {
															position: relative;
															top: 15px;
															&:before {
																	content: '';
																	display: block;
																	width: 1px;
																	border-bottom: 5px solid transparent;
																	position: absolute;
																	top: -15px;
																	left: 50%;
																	transition: width 1s ease-in-out, left 1s ease-in-out;
															}
													}

                            &.active {
                                top: 100px;
                                height: 275px;
                                p:first-of-type {
                                    &:before {
                                        border-bottom: 5px solid $highlight-color;
                                        width: 100px;
                                        left: calc(50% - 50px);
                                    }
                                }
                            }

                        }
                    }
                }
            }
        }
    }
}



// .text {
// 															height: 130px;
// 															top: calc(50% - 130px);
// 															h3 {
// 																	font-size: 36px;
// 																	&:after {
// 																			display: none;
// 																	}
// 															}
// 															.leftTextImage {
// 																	width: 100% !important;
// 																	margin: 0;
// 																	img {
// 																			width: 100% !important;
// 																	}
// 															}
// 													}
