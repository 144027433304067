// #division {
//     &>h2#displayBodyHeader {
//         max-width: 1200px;
//         max-height: 72px;
//         background-size: auto 100% !important;
//         background-repeat: no-repeat !important;
//         padding-top: 0 !important;
// 				height: 100px !important;
// 				margin: 56px auto 20px auto;
//     }
// }

div#siteHeader {
	margin-top: 42px;
}
