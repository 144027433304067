.mediaSlider {
    .side-sponsors & {
        h2 {
            font-size: 18px;
            color: #000;
        }
        .slider {
            background: none;
        }
    }
}

#division {
	.side-sponsors {
		.mediaSlider {
            h2 {
								text-transform:uppercase;
								@include special-header;
            }
            .slider {
                background: none;
            }
        }
    }
}
