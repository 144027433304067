#home.user_mode {

    .site-background {
        background-position: center top!important;
        top: 50px;
    }

    @media only screen and (min-width: 48em) {

        #panelTwo {
            padding: 0;
        }
        #siteContainer {
            background: none;
            .layoutContainer {
                margin-bottom: 25px;

                .column.has-border {
                    background-color: white;
                    border: 2px solid #efefef;
                }
            }
            .layoutContainer.has-gaps {
                display: flex;
                justify-content: space-between;

                .column {
                    margin: 0 10px;
                    &:first-of-type {
                        margin-left: 0;
                    }
                    &:last-of-type {
                        margin-right: 0;
                    }
                }
            }
            .mission-container .column {
                border: 0;
                background: transparent;
            }
        }
    }

    @media only screen and (min-width: 64em) {
        #siteContainer {
            background: none;
            .layoutContainer {
                margin-bottom: 65px;
                margin-top: 0;
            }
            .layoutContainer.has-gaps {

                .column {
                    margin: 0 20px;
                }
            }
        }
    }
}
