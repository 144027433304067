.site-background {
    background-position: center -680px !important;
    background-size: auto auto;
}

#siteContainer {
    &,
    #siteContainer.container-fluid {
        padding: 15px 0;
        width: 100%;
        max-width: $maxWidth;
        min-height: 100%;
        min-height: 100vh;
        min-height: calc(100vh - #{$nav-placeholder-height} - #{$site-footer-height} - #{$account-nav-height-desktop});
        margin: 0 auto 30px auto;
        background: #fff;
        border: 2px solid #efefef;

        #home & {
            border: 0;
				}
				
				.teamPage & {
					padding-top:0;
				}
    }

    @media only screen and (max-width: 1024px) {
        max-width: 1024px;
    }

    #displayBodyHeader:not(.displayBodyHeader-image) {
			  padding: 15px 15px 15px 30px;
        font-size: 2.25em;
        font-weight: 600; //border-bottom: $borderWidth solid $borderColor;
        text-transform: initial;
    }

    #yieldContent.row {
        margin: auto;
    }

    #panelOne {
        padding: 0;
    }

    .yieldPageContent {
        padding: 0;
    }
}

#home.user_mode #siteContainer {

    &,
    #siteContainer.container-fluid {
        max-width: 100%;
        padding-top: 0;
    }

    .layoutInner {
        position: relative;
        display: block;
        margin: auto;
        max-width: $maxWidth;
        width: 100%;
    }
}
