//$site-logo-size: $site-tagline-height + $main-nav-height + $sub-nav-height + 3;
//$site-logo-size-fixed: $site-tagline-height-fixed + $main-nav-height-fixed + $sub-nav-height-fixed + 3;
$site-logo-size: 195px;
$site-logo-size-fixed: 195px;

#sn-site-logo {
	position: absolute; //top: 0;
	bottom: 45px;
	left: 50%;
	margin-left: 0 - $maxWidth/2;
	width: $site-logo-size; //height: $site-logo-size;
	height: 100px;
	padding: 8px;
	padding: 0; //background-color: #fff;
	//border: 1px solid rgba(0,0,0,0.1);
	z-index: 1000;
	overflow: hidden;
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
	transition: all $transition-linear;
	@media only screen and (min-width:64em){
		margin-left:-450px;
	}
	@media only screen and (min-width:75em){
		margin-left: 0 - $maxWidth/2;
	}

	.nav-fixed & {
		width: $site-logo-size-fixed; //height: $site-logo-size-fixed;
		height: 100px;
	}

	.sn-site-logo-wrap {
		display: table;
		table-layout: fixed;
		height: 100%;
		width: 100%;
	}

	.sn-site-logo-background {
		display: table-cell;
		vertical-align: middle;
		overflow: hidden;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
		-webkit-background-size: contain;
  }
  .home & {
    bottom: 5px;
  }
}
