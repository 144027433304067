.staff-bios-container {
    margin-bottom: 30px;

    .textBlockElement {
        .text {
            h2:first-of-type {
                font-weight: 700;
                font-size: 24px;
                text-transform: uppercase;
            }
            h3:first-of-type {
                font-size: 18px;
                font-weight: 700;
                text-transform: uppercase;
                color: $highlight-color;
            }
        }
    }
}

.contactElement {
    &>img {
        height: 100px;
        width: 100px;
        max-width: 100px;
    }
    h3 {
        .contact-element-first-name,
        .contact-element-last-name {
            font-size: 24px;
            line-height: 1.1;
            display: inline-block; //padding-right: 5px;
            text-transform: uppercase;
        }
    }
    p.title {
        color: $highlight-color;
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
        margin-top: 0;
    }
    .icon {
        background-color: transparent;
        font-size: 18px;
        border: 1px solid $highlight-color;
        width: 45px;
        height: 45px;
        color: $highlight-color;
        border-radius: 0;
        &:before {
            font-size: 18px !important;
            line-height: 45px;
            width: 45px;
            left: 0;
            text-align: center;
        }
        &:hover {
            background-color: transparent;
        }
        &.emailLink:before {
            content: "\f0e0";
        }
    }
}

// Custom Link Styles
.pageEl.email-link,
.pageEl.twitter-link,
.pageEl.facebook-link,
 {
    float: left;
    margin-right: 8px;

    .linkElement {
        background: transparent;
        border: 1px solid $highlight-color;
        width: 100%;
        max-width: 45px;
        margin-right: 0;

        h4 a {
            background: transparent;
            text-indent: -9999px;
            position: relative;
            box-shadow: none;
            display: block;
            height: 45px;
            width: 45px;
            &:before {
                content: "\f0c1";
                color: $highlight-color;
                position: absolute;
                font-size: 18px;
                font-family: FontAwesome;
                top: 0;
                left: 0;
                display: block;
                line-height: 45px;
                width: 45px;
                height: 45px;
                text-align: center;
                text-indent: 0;
            }
            &.emailLink {
                &:after {
                    display: none;
                }
            }
        }
    }
}

.pageEl {
    &.email-link {
        .linkElement {
            h4 a.icon.emailLink {
                &:before {
                    content: "\f0e0";
                }
            }
        }
    }
    &.twitter-link {
        .linkElement {
            h4 a.icon.goToLink:before {
                content: "\f099";
            }
        }
    }

    &.facebook-link {
        .linkElement {
            h4 a.icon.goToLink:before {
                content: "\f09A";
            }
        }
    }
}
