#division {
    .news-quick-link {

        .linkElement {
            margin: 0 0 15px 0;
            h4 {

                a {
                    background-color: #efefef;
                    color: $highlight-color;
                    box-shadow: none;
                    font-size: 18px;
                    font-weight: 400;
                    text-transform: none;
                    &:after {
                        content: '\f105';
                        position: absolute;
                        right: 10px;
                        color: $highlight-color;
                        font-size: 30px;
                        transition: right .3s, color .3s;
                    }
                    &:hover {
                        color: #000;
                        &:after {
                            color: #000;
                            right: 15px;
                        }
                    }
                }
            }
        }
    }


}

.pageElement.surveyLinkElement {
	position: relative;
	margin-top:0;

	a {
			background-color: $highlight-color;
			color: #fff;
			box-shadow: none;
			font-size: 16px;
			font-weight: 400;
			text-transform: none;
			text-align: left;
			padding-left: 45px;
			line-height:1.1;

			span:after {
					display: none;
			}
	}
	&:before {
			content: "";
			background: url(../images/custom/mountain-icon-1.png) center center no-repeat;
			background-size: 100% auto;
			left: 10px;
			height: 18px;
			width: 18px;
			position: absolute;
			cursor: pointer;
			top: calc(50% - 12px);
	}
}
