.custom-text-block .textBlockElement {

	border: 2px solid #efefef;
	padding-bottom: 30px;

	.largeTextImage {
		width: 100% !important;
		margin-top: 0;

		img {
			width: 100% !important;
		}
	}
	.text {
		padding-left: 20px;
		padding-right: 20px;
	}
}


@media only screen and (min-width:48em) {
	.user_mode {
		.get-involved-container {
			background: white;
			margin-bottom: 40px!important;

			.column-1 {
				display: flex;
				flex-flow: row wrap;
				justify-content: space-between;
				.pageEl {
					box-sizing: border-box;
					margin: 0;
					padding: 0;
					max-width: 49%;
					&:first-of-type {
						width: 100%;
						max-width: 100%;
						.textBlockElement {
							margin-bottom: 0;
						}
					}
				}

				.custom-text-block {

					&:first-of-type {
						padding-left: 0;
					}
					&:last-of-type {
						padding-right: 0;
					}
				}
			}
		}
	}
}

@media only screen and (min-width:64em) {

	.user_mode {
		.get-involved-container {
			.column-1 {
				padding: 0 calc((100vw - 1200px) / 2);
			}
		}
	}
}
