@media only screen and (min-width:64em) {
    #division, .teamPage {
        #topNav {

            #sn-site-logo {
                bottom: 43px;
            }
            .theme-sub-nav {

                display: block !important;
                background: $highlight-color;
                .theme-nav a {
                    color: #fff;
                }
                .theme-nav-style-dropdown {
                    max-width: 1200px;
                    left: 0;
                    &>.theme-nav-item {

                        border-right: 0;
                        cursor: pointer;
                        // &:first-child {
                        //     border: 1px solid white;
                        //     &:after {
                        //         content: '\f104';
                        //         position: relative;
                        //         font-size: 16px;
                        //         display: inline-block;
                        //         width: 25px;
                        //         height: 35px;
                        //     }
                        // }
                    }
                }

                // ul li:nth-child(2) {
                //     font-weight: 700;
                //     &:after {
                //         content: '\f105';
                //         position: relative;
                //         font-size: 16px;
                //         display: inline-block;
                //         width: 20px;
                //         padding: 0;
                //         margin-right: -10px;
                //         font-family: 'fontawesome';
                //     }
                // }
                // .theme-nav>.theme-nav-title {
                //     display: none;
                // }



                .theme-nav>.theme-nav-item.selected>.theme-nav-link {
                    background-color: transparent;
                }
                .theme-nav>.theme-nav-item>.theme-nav-link:not(x):hover {
                    background: transparent;
                    color: #000;
								}
								.theme-nav > .theme-nav-item > .theme-nav-link {
									color: #ffffff;
							}
            }
        }
    }
}
