.user_mode {
    .custom-slider-ctas-container {
        background: #f5f5f5;
        padding-top: 15px !important;
        .column-1 {
            flex-flow: column wrap;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            height: 100%;

            .pageEl {
                background: rgba(255, 255, 255, .85);
                flex: 1 0 auto;
                padding: 0 15px;
                height: 20%;
                width: 100%;
                margin: 0 0 5px 0;
                border-top: 0;
                border-left: 10px solid $accent-color;
                &:nth-child(even) {
                    border-left: 10px solid $highlight-color;
                }
                .pageElement {
                    height: 100%!important;
                    .sn-call-to-action {
                        height: inherit !important;
                        img {
                            min-height: 50%;
                            max-height: 60%;
                            max-width: 80%;
                        }
                    }
                }
            }
        }
    }
}


@media only screen and (min-width:48em) {
    .user_mode {
        .custom-slider-ctas-container {
            background: transparent; //height: 302px;
            //max-height: 302px;
            width: 100%;
            max-width: 20%;
            margin: auto!important;
            padding: 6px 0 0 0 !important;
            position: absolute;
            top: 0;
            right: 8%;
            transition: top 2s ease;
            .column-1 {
                background: transparent;
                flex-flow: column wrap;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                height: 100%;
                .pageEl {
                    flex: 1 0 auto;
                    height: 20%;
                    width: 100%;
                    margin: 0 0 5px 0;
                    border-top: 0;
                    border-left: 10px solid $accent-color;
                    &:nth-child(even) {
                        border-top: 0;
                        border-left: 10px solid $highlight-color;
                    }
                    .pageElement {
                        margin: 0;
                        width: 100%;
                        .sn-call-to-action {
                            //max-height: 36px;
                            img {}
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (min-width:87em) {
	.user_mode {
			.custom-slider-ctas-container {
					right: 12%;


			}
	}
}
// @media only screen and (min-width:80em) {
//     .user_mode {
//         .custom-slider-ctas-container {
//             max-width: 250px;
//             margin: auto!important;
//             padding: 0!important;
//             position: absolute;
//             top: 34px;
//             right: 200px;
//             .column-1 {
//                 flex-flow: column wrap;
//                 justify-content: flex-start;
//                 align-items: center;
//                 width: 100%;
//                 max-width: 250px;
//                 .pageEl {
//                     width: 100%;
//                     .pageElement {
//                         width: 100%;
//                         .sn-call-to-action {
//                             max-height: 100%;
//                             img {
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }
// @media only screen and (min-width:90em) {
//     .user_mode {
//         .custom-slider-ctas-container {
//             max-width: 330px;
//             margin: auto!important;
//             padding: 0!important;
//             position: absolute;
//             top: 25px;
//             right: 200px;
//             .column-1 {
//                 flex-flow: column wrap;
//                 justify-content: flex-start;
//                 align-items: center;
//                 width: 100%;
//                 max-width: 330px;
//                 .pageEl {
//                     width: 100%;
//                     .pageElement {
//                         width: 100%;
//                         img {
// 												}
//                     }
//                 }
//             }
//         }
//     }
// }
// @media only screen and (min-width:112em) {
//     .user_mode {
//         .custom-slider-ctas-container {
//             max-width: 350px;
//             margin: auto!important;
//             padding: 0!important;
//             position: absolute;
//             top: 100px;
//             right: 250px;
//             .column-1 {
//                 flex-flow: column wrap;
//                 justify-content: flex-start;
//                 align-items: center;
//                 width: 100%;
//                 max-width: 350px;
//                 .pageEl {
//                     width: 100%;
//                     .pageElement {
//                         width: 100%;
//                         img {
// 												}
//                     }
//                 }
//             }
//         }
//     }
// }
.has-mobile-nav {

    .user_mode {
        .custom-slider-ctas-container {

            .column-1 {


                .pageEl {

                    &:nth-child(even) {}
                    .pageElement {

                        .sn-call-to-action {
													//height:100% !important;
													min-height:50px;

                            img {
                                min-height: 50px;
                            }
                        }
                    }
                }
            }
        }
    }
}
