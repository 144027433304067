.global-footer {
    margin-bottom: 0 !important;
    background: $accent-color;
    padding: 0 calc((100vw - 1200px) / 2);
    & * {
        color: #fff;
    }
    a:hover {
        color: #fff;
    }

    .column-1 {
        .footer-logo {
            padding-top: 15px;
            max-width: 160px;
            border-bottom: 2px solid white;
            margin: 0 auto;
        }
        .sn-social-media-list {
            text-align: center;
            width: 100%;
            padding: 0;
            margin: 0;
            &.dark .sn-social-media-icon {
                background: transparent;
                margin: 0;
                width: .8em;
            }
            .icon-facebook:before {
                content: "\f230";
            }
            .icon-twitter:before {
                content: "\f081";
            }
            .icon-youtube:before {
                content: "\f16a";
            }
        }
    }
    .column-2 {
        padding-left: 25px;

        .pageEl {

            margin: 0;
            &.address-block .text {
								padding-left: 23px;
								p:first-of-type {
									margin-left: -25px;
							}
            }
            .textBlockElement {
                h3 {
                    margin-bottom: 0;
                    span {
                        color: #fff;
                        font-size: 18px;
                        line-height: 2;
                        margin-bottom: 0;
                        padding: 0;
                        &:after {
                            display: none;
                        }
                    }
                }
                .text {
                    font-weight: 400;
                    line-height: 2;

                    p {
                        font-size: .8em;
                        line-height: 2;
                        padding: 0;
                        margin: 0;
                    }
                    a {
                        font-weight: 400;
                        line-height: 2;
                        &.address-link {
                            position: relative;

                            &:before {
                                content: "\f041";
                                color: #fff;
                                display: inline-block;
                                font-family: FontAwesome;
                                font-size: 1em;
                                height: 1em;
                                line-height: 1em;
                                margin: .1em;
                                padding: 0 5px 0 0;
                                text-align: center;
                                text-decoration: none;
                                text-rendering: auto;
                                transition: background-color .25s ease-in-out;
                                width: 1em;
                                position: absolute;
                                left: -23px;
                                top: 0;
                            }
                        }
                        &.phone-link {
                            position: relative;

                            &:before {
                                content: "\f095";
                                color: #fff;
                                display: inline-block;
                                font-family: FontAwesome;
                                font-size: 1em;
                                height: 1em;
                                line-height: 1em;
                                margin: .1em;
                                padding: 0 5px 0 0;
                                text-align: center;
                                text-decoration: none;
                                text-rendering: auto;
                                transition: background-color .25s ease-in-out;
                                width: 1em;
                                position: absolute;
                                left: -23px;
                                top: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

#siteFooter {
    background: $accent-color;
    & * {
        color: #fff;
    }
}


@media only screen and(min-width:48em) {


    .global-footer {
        margin-bottom: 0 !important;
        background: $accent-color;
        padding: 0 calc((100vw - 1200px) / 2);
        & * {
            color: #fff;
        }

        .column-1 {
            width: 30%;
            .footer-logo {
                padding-top: 15px;
                max-width: 160px;
                border-bottom: 2px solid white;
            }
            .sn-social-media-list {
                text-align: center;
                max-width: 160px;
                padding: 0;
                margin: 0;
                &.dark .sn-social-media-icon {
                    background: transparent;
                    margin: 0;
                    width: .8em;
                }
                .icon-facebook:before {
                    content: "\f230";
                }
                .icon-twitter:before {
                    content: "\f081";
                }
                .icon-youtube:before {
                    content: "\f16a";
                }
            }
        }
        .column-2 {
            width: 70%;
            display: flex;
            flex-flow: row nowrap;
            .pageEl {
                width: 33.3333%;
                margin: 0;
                &.address-block .text {
										padding-left: 23px;

                }
                .textBlockElement {
                    h3 {
                        margin-bottom: 0;
                        span {
                            color: #fff;
                            font-size: 18px;
                            line-height: 2;
                            margin-bottom: 0;
                            padding: 0;
                            &:after {
                                display: none;
                            }
                        }
                    }
                    .text {
                        font-weight: 400;
                        line-height: 2;

                        p {
                            font-size: .8em;
                            line-height: 2;
                            padding: 0;
                            margin: 0;
                        }
                        a {
                            font-weight: 400;
                            line-height: 2;
                            &.address-link {
                                position: relative;

                                &:before {
                                    content: "\f041";
                                    color: #fff;
                                    display: inline-block;
                                    font-family: FontAwesome;
                                    font-size: 1em;
                                    height: 1em;
                                    line-height: 1em;
                                    margin: .1em;
                                    padding: 0 5px 0 0;
                                    text-align: center;
                                    text-decoration: none;
                                    text-rendering: auto;
                                    transition: background-color .25s ease-in-out;
                                    width: 1em;
                                    position: absolute;
                                    left: -23px;
                                    top: 0;
                                }
                            }
                            &.phone-link {
                                position: relative;

                                &:before {
                                    content: "\f095";
                                    color: #fff;
                                    display: inline-block;
                                    font-family: FontAwesome;
                                    font-size: 1em;
                                    height: 1em;
                                    line-height: 1em;
                                    margin: .1em;
                                    padding: 0 5px 0 0;
                                    text-align: center;
                                    text-decoration: none;
                                    text-rendering: auto;
                                    transition: background-color .25s ease-in-out;
                                    width: 1em;
                                    position: absolute;
                                    left: -23px;
                                    top: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    #siteFooter {
        background: $accent-color;
        & * {
            color: #fff;
        }
    }
}


@media only screen and(min-width:64em) {

    .global-footer {
        .column-1 {

            .footer-logo {

                max-width: 280px;
            }
            .sn-social-media-list {

                max-width: 280px;
            }
        }
        .column-2 {
            .pageEl {
                width: 30%;
                .textBlockElement {
                    .text {
                        p {
                            font-size: 1em;
                        }
                    }
                }
            }
        }
    }
}
