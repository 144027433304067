#division {

    .newsSlideShow-container.layout_classic.aspect_ratio_16_9 {
        padding-bottom: 0;
    }

    .newsSlideShow-headline {
        top: -120px !important;
        h1 {
            background: none;
            &:before {
                display: none;
            }
            a {
                size: 36px;
            }
        }
        &>p {
            display: none;
        }
    }

    .newsSlideShow-article {
        border: 0;
    }
    .newsSlideShow-thumbnails {
        box-shadow: none;
				bottom: 30px;
				background:none;

        a:before {
            background-color: #efefef;
            height: 1em;
            width: 1em;
        }
    }
    .newsSlideShow-thumbnails a:hover:before,
    .newsSlideShow-thumbnails a.current:before {
        background-color: $highlight-color;
        height: 1em;
        width: 1em;
        transform: none;
    }

    .newsSlideShow-prev.pill-small-left,
    .newsSlideShow-next.pill-small-right {
        color: $highlight-color;
        box-shadow: none;
        width: 3.5rem;
        height: 3.5rem;
				bottom: 34px;
				background:none;
				
				
    }
    .newsSlideShow-prev.pill-small-left:before {
        content: '\f104';
        font-size: 2em;
    }
    .newsSlideShow-next.pill-small-right:before {
        content: '\f105';
        font-size: 2em;
    }
}
