.user_mode {

    .news-container {

        .pageEl {
            .aggregatorElement {
                text-align: center;
                h3.aggHeader {
                    //padding-top: 0;
                    &:after {
                        margin-left: auto;
                        margin-right: auto;
                        margin-bottom: 0;
                    }
                }
                h4 {
                    a {
                        color: black;
                        font-size: 24px;
                    }
                }
                .readMore {
                    display: none;
                }
                .newsDate,
                .newsAuthor {
                    font-size: 14px;
                    color: #222;
                }
            }

            .textBlockElement {

                h3 {
                    text-align: center !important; //padding: 0;
                    span:after {
                        padding: 0;
                        margin-left: auto;
                        margin-right: auto;
                        margin-bottom: 0;
                    }
                }
            }
        }

        .side-sponsors {
            max-width: 200px;
            margin: 0 auto;
            .mediaSlider {
                h2 {
                    text-align: center;
                }
                .slider {
                    margin-top: 0;
                }
            }
        }
    }
}

@media only screen and (min-width:48em) {

    .user_mode {
        .news-container {
            .pageEl {
                .aggregatorElement {
                    text-align: left;
                    h3.aggHeader {
                        &:after {
                            margin-left: 0;
                        }
                    }
                }

                .textBlockElement {

                    h3 {

                        span {
                            //padding: 0;
                            font-size: 20px;
                        }
                    }
                }
            }
            .side-sponsors {
                max-width: 150px;
            }
        }
    }
}


@media only screen and (min-width:64em) {

    .user_mode {
        .news-container {
            .pageEl {
                .textBlockElement {
                    h3 {
                        text-align: left!important; //padding: 0;
                        span {
                            //padding: 0;
                            font-size: 24px;
                            white-space: nowrap;
                            &:after {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
            .side-sponsors {
                max-width: 100%;
                .mediaSlider {
                    h2 {
                        text-align: left;
                    }
                }
            }
        }
    }
}


@media only screen and (min-width:75em) {

    .user_mode {
        .news-container {
            .pageEl {
                .textBlockElement {
                    h3 {
                        span {
                            font-size: 32px;
                        }
                    }
                }
            }
        }
    }
}
