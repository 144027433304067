.stay-connected-container {

	.column-1 {

		.pageEl {
			box-sizing: border-box;
			margin: 0;
			padding: 0 10px;

			&:first-of-type {
				.textBlockElement {
					margin-bottom: 0;
				}
			}
			.linkElement {
				margin: 5px 0;
				h4 a {
					text-align: center;
					box-shadow: none;
					font-size: 18px;
					font-weight: 400;
					text-transform: none;
					&:after {
						display: none;
					}
				}
			}
			&.social-link-twitter {
				.linkElement h4 a {
					background: #5da1ef;
					&:before {
						color: #fff;
						content: "\f099";
						font-family: FontAwesome;
						font-size: 18px;
						display: inline-block;
						line-height: .8em;
						margin: 0 0 0 6px;
						padding: 0;
						position: relative;
						transition: color .2s;
						left: -10px;
					}
					&:hover {
						background-color: #4081cc;
					}
				}
			}
			&.social-link-facebook {
				.linkElement h4 a {
					background: #475995;

					&:before {
						color: #fff;
						content: "\f09a";
						font-family: FontAwesome;
						font-size: 18px;
						display: inline-block;
						line-height: .8em;
						margin: 0 0 0 6px;
						padding: 0;
						position: relative;
						transition: color .2s;
						left: -10px;
					}
					&:hover {
						background-color: #31437e;
					}
				}
			}
			&.social-link-instagram {
				.linkElement h4 a {
					background: #d93175;
					&:before {
						color: #fff;
						content: "\f16d";
						font-family: FontAwesome;
						font-size: 18px;
						display: inline-block;
						line-height: .8em;
						margin: 0 0 0 6px;
						padding: 0;
						position: relative;
						transition: color .2s;
						left: -10px;
					}
					&:hover {
						background-color: #b1215d;
					}
				}
			}
		}
	}
}

@media only screen and(min-width:48em) {

	.stay-connected-container {

		.column-1 {

			display: flex;
			flex-flow: row wrap;

			.pageEl {
				box-sizing: border-box;
				margin: 0;
				padding: 0 10px;
				width: calc(100% / 3);
				&:first-of-type {
					width: 100%;
					max-width: 100%;
					.textBlockElement {
						margin-bottom: 0;
					}
				}
				.linkElement {
					margin: 15px 0;
					h4 a {
						text-align: center;
						box-shadow: none;
						font-size: 18px;
						font-weight: 400;
						text-transform: none;
						&:after {
							display: none;
						}
					}
				}
				&.social-link-twitter {
					.linkElement h4 a {
						background: #5da1ef;
						&:before {
							color: #fff;
							content: "\f099";
							font-family: FontAwesome;
							font-size: 18px;
							display: inline-block;
							line-height: .8em;
							margin: 0 0 0 6px;
							padding: 0;
							position: relative;
							transition: color .2s;
							left: -10px;
						}
						&:hover {
							background-color: #4081cc;
						}
					}
				}
				&.social-link-facebook {
					.linkElement h4 a {
						background: #475995;

						&:before {
							color: #fff;
							content: "\f09a";
							font-family: FontAwesome;
							font-size: 18px;
							display: inline-block;
							line-height: .8em;
							margin: 0 0 0 6px;
							padding: 0;
							position: relative;
							transition: color .2s;
							left: -10px;
						}
						&:hover {
							background-color: #31437e;
						}
					}
				}
				&.social-link-instagram {
					.linkElement h4 a {
						background: #d93175;
						&:before {
							color: #fff;
							content: "\f16d";
							font-family: FontAwesome;
							font-size: 18px;
							display: inline-block;
							line-height: .8em;
							margin: 0 0 0 6px;
							padding: 0;
							position: relative;
							transition: color .2s;
							left: -10px;
						}
						&:hover {
							background-color: #b1215d;
						}
					}
				}
			}
		}
	}
}
